import { CSSProperties } from "react";

export const setupContainerDesktop: CSSProperties = {
  // padding: "25px 96px 0px 0px", // top, right , bottom, left
  padding: "25px 5% 0px 0px", // top, right , bottom, left
  display: "flex",
  flexFlow: "column",
  height: "100%",
  flexGrow: 1
};

export const setupContainerMobile: CSSProperties = {
  padding: "25px 5% 0px 0px", // top, right , bottom, left
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1
};
export const setupContainerTablet: CSSProperties = {
  padding: "25px 5% 0px 0px", // top, right , bottom, left
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1
};
export const setupContainerLaptop: CSSProperties = {
  padding: "25px 5% 0px 0px", // top, right , bottom, left
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1
};

export const logoSliderClassInfoMobile: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  // flex-end makes the element align to the end of the container, which can be top, right, bottom or left depending on the flexDirection
  justifyContent: "center"
  // alignContent: "center"
};

export const logoSliderClassInfoTablet: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end"
};

export const logoSliderClassInfoLaptop: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end"
};

export const logoSliderClassInfoDesktop: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end"
};

export const powaLogoContainerMobile: CSSProperties = {
  justifyContent: "center",
  width: "100%",
  marginLeft: "3%"
};

export const powaLogoContainerLaptop: CSSProperties = {
  justifyContent: "start",
  width: "10vw"
};

export const powaLogoContainerBase: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
};

export const powaLogo: CSSProperties = {
  maxHeight: "10vh"
  // paddingRight: "1vw"
  // paddingLeft: "50px"
};

export const volumeDiv: CSSProperties = {
  display: "flex",
  // alignItems: "center",
  // justifyContent: "flex-start",
  width: "43px"
};

export const volumeIcon: CSSProperties = {
  height: "40px"
};

export const devText: CSSProperties = {
  cursor: "pointer",
  color: "white"
};

export const sliderContainerMobile: CSSProperties = {
  width: "96.6%",
  // border: "2px solid red",
  height: "100%",
  margin: "20px 0px 20px 5.3%" // top, right , bottom, left
};

export const sliderContainerLaptop: CSSProperties = {
  // width: "53.437vw", // 1026/1920
  width: "53vw", // 1026/1920
  height: "47.5923vh", // 514/1080
  minHeight: "420px"
};

export const sliderContainerBase: CSSProperties = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",

  padding: "20px",
  backgroundColor: "rgba(217, 217, 217, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px"
};

export const classIdPunchGoalStartMobile: CSSProperties = {
  width: "96.6%", // 574/1920
  minHeight: "270px",
  padding: "40px 40px 24px 40px",
  marginLeft: "5.3%",
  gap: "10px"
};

export const classIdPunchGoalStartLaptop: CSSProperties = {
  // width: "29.895vw", // 574/1920
  // height: "47.5923vh", // 514/1080
  width: "100%", // 574/1920
  maxWidth: "30vw",
  // height: "100%", // 514/1080
  minHeight: "420px",
  padding: "40px 40px 24px 40px",
  marginLeft: "20px"
};

export const classIdPunchGoalStartBase: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "white",
  justifyContent: "space-between",

  backgroundColor: "rgba(217, 217, 217, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px"
};

export const classIdContainerTablet: CSSProperties = {
  fontSize: "40px"
};

export const classIdContainerLaptop: CSSProperties = {
  fontSize: "40px"
};

export const classIdContainerDesktop: CSSProperties = {
  fontSize: "64px"
};

export const classIdContainerBase: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "left",
  width: "100%",
  justifyContent: "space-between",
  fontFamily: "'Barlow Semi Condensed', sans-serif"
};

export const classIdLabel: CSSProperties = {
  marginBlock: "0",
  fontWeight: "700"
};

export const classIdDisplay: CSSProperties = {
  marginBlock: "0",
  fontWeight: "normal",
  paddingLeft: "10px"
};

export const punchGoalContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  color: "white",
  width: "100%"
};

export const punchGoalLabelTablet: CSSProperties = {
  fontSize: "24px"
};
export const punchGoalLabelLaptop: CSSProperties = {
  fontSize: "32px"
};
export const punchGoalLabelDesktop: CSSProperties = {
  fontSize: "40px"
};
export const punchGoalLabelBase: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontSize: "40px",
  fontWeight: "700"
};

export const punchGoalInputBase: CSSProperties = {
  // width: "100%",
  background: "rgba(0, 0, 0, 0.32)",
  border: "none",
  borderRadius: "4px",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600,
  color: "white",
  marginTop: "10px",
  padding: "0px 10px"
};
export const punchGoalInputTablet: CSSProperties = {
  fontSize: "18px",
  height: "36px"
};
export const punchGoalInputLaptop: CSSProperties = {
  fontSize: "36px",
  height: "48px"
};
export const punchGoalInputDesktop: CSSProperties = {
  fontSize: "40px",
  height: "60px"
};

export const buttonStyleMobile: CSSProperties = {
  height: "50px",
  fontSize: "18px"
};

export const buttonStyleLaptop: CSSProperties = {
  height: "50px",
  fontSize: "18px"
};

export const buttonStyleDesktop: CSSProperties = {
  height: "70px",
  fontSize: "24px"
};

export const buttonStyleBase: CSSProperties = {
  background: "#f2662D",
  width: "100%",
  borderRadius: "8px",
  letterSpacing: "2px",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 700
};

// user grid styles:
export const outerContainerMobile: CSSProperties = {
  padding: "10px 10px 30px 0px" // top, right , bottom, left
};

export const outerContainerLaptop: CSSProperties = {
  padding: "0px 15px 30px 0px"
};

export const outerContainerBase: CSSProperties = {
  // margin: "20px 96px 30px 96px", // top, right , bottom, left
  margin: "20px 0% 30px 5.3%",
  // margin: "20px 0px 0px 96px", // top, right , bottom, left // trying to make right hand side align no matter the screen size
  backgroundColor: "rgba(217, 217, 217, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  // minHeight: "400px",
  minHeight: "20vh",
  // minWidth: "900px",
  maxWidth: "100%",
  width: "89.947vw", // 1727/1920
  // paddingBottom: "50px",
  height: "400px",
  overflow: "auto"
};

export const centeringGrid: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start", // Align to start vertically
  alignItems: "center",
  position: "relative",
  height: "100%",
  width: "100%",
  minHeight: "350px" // TODO remove hard px number
};

export const gridContainerMobile: CSSProperties = {
  flexWrap: "nowrap", // stack vertically
  flexDirection: "column",
  alignItems: "center"
};

export const gridContainerLaptop: CSSProperties = {
  flexWrap: "wrap",
  flexDirection: "row",
  alignItems: "flex-start"
};

export const gridContainerBase: CSSProperties = {
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
  height: "100%",

  marginTop: "0px",
  marginLeft: "0px",
  // padding: "0px 0px 50px 0px",
  margin: "0px 0px 0px 0px",
  // gap: "10px",
  minHeight: "100%" // Adjust based on your exact needs
  // minHeight: "calc(100% - 20px)" // from usecount positioning
};

export const userCardSetupGridStyle: CSSProperties = {
  paddingLeft: "15px",
  paddingBottom: "15px",
  paddingTop: "15px",
  maxWidth: "400px" // TODO this is the outer container, not the actual box so things look bad
  // width: "80vw"
};

export const userCountStyles: CSSProperties = {
  color: "#FFFFFF", // TODO fix this for mobile view
  fontSize: "2rem",
  position: "absolute",
  right: "150px", // Align with your container's padding
  bottom: "-15px", // Align with your container's padding
  display: "flex",
  justifyContent: "end"
};

import { CSSProperties } from "react";

export const containerStyles: CSSProperties = {
  // marginTop: "20px",
  maxWidth: "100%",
  padding: "0px 0px 120px 0px" // top, right , bottom, left
};

export const gridContainerStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0px",
  width: "100%"
};

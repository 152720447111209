import { CSSProperties } from "react";

export const modalMobile: CSSProperties = {
  width: "90%"
  // maxWidth: 960,
  // height: "90%"
  // maxHeight: 630
};

export const modalTablet: CSSProperties = {
  width: 750,
  maxWidth: 750,
  height: 500,
  maxHeight: 500
};

export const modalLaptop: CSSProperties = {
  width: 960,
  maxWidth: 960,
  height: 630,
  maxHeight: 630
};

export const modalBase: CSSProperties = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#3B3B3B",
  border: "12px solid #262626",
  borderRadius: "5px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  outline: "none"
  // p: 4
};

export const roundedCorners: CSSProperties = {
  borderRadius: "5px",
  border: "none",
  height: "100%"
};

export const modalTitleMobile: CSSProperties = {
  padding: "10px 10px 10px 10px", // top, right , bottom, left
  fontSize: "24px"
};

export const modalTitleLaptop: CSSProperties = {
  fontSize: "75px",
  padding: "35px 50px 45px 50px"
};

export const modalTitleBase: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: 600,
  letterSpacing: "0.46px",
  background: "#1E1E1E",
  color: "#FFFFFF",
  position: "relative",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
};

export const joinNowClassIdMobile: CSSProperties = {
  minWidth: "140px"
};

export const joinNowClassIdLaptop: CSSProperties = {
  minWidth: "440px"
};

export const joinNowClassIdBase: CSSProperties = {
  display: "flex",
  justifyContent: "end",
  gap: "10px"
};

export const joinNow: CSSProperties = {
  filter: "brightness(70%)"
};

export const xButtonContainerMobile: CSSProperties = {
  top: 42,
  right: -5
};
export const xButtonContainerLaptop: CSSProperties = {
  top: -42,
  right: -5
};

export const xButtonContainerBase: CSSProperties = {
  position: "absolute",
  padding: "10px",
  zIndex: "3"
};

export const xButton: CSSProperties = {
  height: "30px",
  color: "#FFFFFF",
  filter: "brightness(70%)",
  cursor: "pointer"
};

export const modalContent: CSSProperties = {
  color: "#3B3B3B",
  height: "450px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "40px 0px 60px 0px", // top, right , bottom, left
  position: "relative"
};

export const punchGoalContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  margin: "20px 0px 20px 0px"
};

export const punchGoalLabelMobile: CSSProperties = {
  fontSize: "20px"
};

export const punchGoalLabelLaptop: CSSProperties = {
  fontSize: "40px"
};

export const punchGoalLabelBase: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: "700",
  color: "#FFFFFF"
};

export const punchGoalInputMobile: CSSProperties = {
  height: "40px",
  fontSize: "30px",
  width: "200px"
};

export const punchGoalInputLaptop: CSSProperties = {
  height: "60px",
  fontSize: "40px",
  width: "490px"
};

export const punchGoalInputBase: CSSProperties = {
  background: "rgba(0, 0, 0, 0.32)",
  border: "none",
  borderRadius: "4px",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600,
  color: "white",
  marginTop: "10px",
  padding: "0px 10px"
};

export const resumeButtonStyle: CSSProperties = {
  background: "#f2662D"
};
export const quitButtonStyle: CSSProperties = {
  background: "rgba(241, 67, 67, 0.85)" // F14343
};

export const buttonMobile: CSSProperties = {
  width: "220px",
  maxWidth: "100%",
  fontSize: "16px",
  letterSpacing: "0px",
  height: "50px"
};

export const buttonLaptop: CSSProperties = {
  width: "500px",
  fontSize: "24px",
  letterSpacing: "2px",
  height: "70px"
};

export const buttonBase: CSSProperties = {
  borderRadius: "8px",
  fontFamily: "Barlow, sans-serif",
  fontWeight: 800
};

export const volumeDiv: CSSProperties = {
  position: "absolute", // Positioning volume div absolutely
  right: "10px",
  bottom: "0%",
  transform: "translateY(-30%)", // Adjust vertical alignment
  width: "43px", // Set width to enclose the icon
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start"
};

export const volumeIcon: CSSProperties = {
  height: "40px"
};

import { useState, useEffect, CSSProperties } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import { useClassContext } from "../../Contexts/ClassContext";
import { BoxingClass } from "../helpers/CustomTypes";
import addComma from "../helpers/addComma";
import sumSessions from "../03.2-ContinuousPage/sumSessions";
import useResize from "../../Hooks/useResize";
import { selectCSSClass } from "../helpers/selectCSSClass";

const sumTotalPunchesInClass = (boxingClass: BoxingClass, classId: string): number => {
  if (!boxingClass || !boxingClass[classId] || Object.keys(boxingClass[classId].scores).length === 0) {
    return 0;
  }

  const classScores = boxingClass[classId].scores;
  let totalPunches = 0;

  // Iterate over the users in the scores object
  Object.values(classScores).forEach((user) => {
    // console.log("adding punches to total: ", sumSessions(user, "Punches"));
    totalPunches += sumSessions(user, "Punches");
  });

  // console.log("totalPunches:", totalPunches);
  return totalPunches;
};

export default function ClassGoalProgress() {
  const { classId, boxingClass, classPunchGoal } = useClassContext();
  const [totalPunches, setTotalPunches] = useState(0);
  const rawWidth: number = useResize()[0];

  useEffect(() => {
    const total = sumTotalPunchesInClass(boxingClass, classId);
    // console.log("total punches in class: ", total);
    setTotalPunches(total);
  }, [boxingClass, classId, classPunchGoal]);

  // Calculate the progress as a percentage
  // const progress = (totalPunches / classPunchGoal) * 100;
  const progress = Math.min((totalPunches / classPunchGoal) * 100, 100); // takes the smaller of the two values, avoid going above 100 so the bar is filled with numbers > 1000

  const fontSize: string = rawWidth > 900 ? "5rem" : "20px";
  const paddingTop: string = rawWidth > 900 ? "0px" : "50px";

  let addLineBreak = "something has gone terribly wrong";

  // this is trying to add a line break to put punches underneath the numbers, but the \n doesn't show up
  if (rawWidth > 900) {
    addLineBreak =
      classPunchGoal === 0 ? `${addComma(totalPunches)} Punches` : `${addComma(totalPunches)} / ${addComma(classPunchGoal)} Punches`;
  } else if (rawWidth <= 900) {
    addLineBreak =
      classPunchGoal === 0 ? `${addComma(totalPunches)} Punches` : `${addComma(totalPunches)} / ${addComma(classPunchGoal)} \n Punches`;
  } else {
    console.log("rawWidth not computing properly, rawWidth: ", rawWidth);
  }

  // {
  //   rawWidth > 900 &&
  //     (classPunchGoal === 0 ? `${addComma(totalPunches)} Punches` : `${addComma(totalPunches)} / ${addComma(classPunchGoal)} Punches`);
  // }
  // {
  //   rawWidth <= 900 &&
  //     (classPunchGoal === 0 ? `${addComma(totalPunches)} Punches` : `${addComma(totalPunches)} / ${addComma(classPunchGoal)}\nPunches`);
  // }

  return (
    <Box sx={{ width: "100%", height: "100%", borderRadius: 0, overflow: "hidden", position: "relative" }}>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: "100%", // Make the LinearProgress fill the Box height

          backgroundColor: "#3B3B3B", // Non-completed portion color
          " .MuiLinearProgress-bar": {
            background: "linear-gradient(to right, #F2662D, #F9A82C)"
          }
        }}
      />
      <Typography
        variant="body1"
        sx={{
          position: "absolute",
          width: "100%",
          transform: "translateY(-125px)",
          height: "100%",
          textAlign: "center",
          color: "#FFFFFF", // Choose color that suits your design
          fontFamily: "'Barlow Semi Condensed', sans-serif",
          fontWeight: "bold",
          fontSize: fontSize,
          paddingTop: paddingTop,
          backgroundColor: "transparent",
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)" // we need three shadows instead of one at 75%
        }}
      >
        {addLineBreak}
      </Typography>
    </Box>
  );
}

import { useLocation } from "react-router-dom";
import { CSSProperties } from "react";

import { displayTimeDecrement } from "./DisplayTimeDecrement";
// import { addMockUser } from "./addMockUser";
import { useClassContext } from "../../Contexts/ClassContext";
import { getBottomBarContent } from "./getBottomBarContent";
import useResize from "../../Hooks/useResize";
import { selectCSSClass } from "../helpers/selectCSSClass";

import ClassGoalProgress from "../MUIComponents/ClassGoalProgress";
import PauseModal from "../MUIComponents/PauseModal/PauseModal";

import {
  bottomBar,
  pauseButtonWithSubtext,
  timerText,
  timerMobile,
  timerLaptop,
  timerBase,
  trapezoidBase,
  trapezoidMobile,
  trapezoidLaptop
} from "./Boxing.styles";

export default function BottomBar(): JSX.Element {
  const { timer, classId, isTimerRunning, startTimer, stopTimer, setOpenModal, prevMode, mode, continuousMode } = useClassContext();
  const location = useLocation();
  const boxingURL = location.pathname.endsWith("/boxing");
  // const finishURL = location.pathname.endsWith("/finish");
  const continuoushURL = location.pathname.endsWith("/continuous");

  const rawWidth: number = useResize()[0];

  const handleTimerButtonClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setOpenModal(true);
    if (boxingURL) {
      // we don't want any timing nonsense at /finish
      isTimerRunning ? stopTimer("pause") : startTimer(prevMode);
    } else if (continuoushURL) {
      if (mode === "active") {
        stopTimer("pause");
      } else if (mode === "pause") {
        startTimer(prevMode);
      } else {
        console.error("mode is not valid for continuousMode: ", continuousMode, ". Mode: ", mode);
      }
    }
  };

  const trapezoidCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: trapezoidMobile,
    laptop: trapezoidLaptop
  });

  const timerCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: timerMobile,
    laptop: timerLaptop
  });

  return (
    <div style={bottomBar}>
      <div onClick={handleTimerButtonClick} style={pauseButtonWithSubtext}>
        {getBottomBarContent(location.pathname, isTimerRunning, mode, rawWidth)}
      </div>
      <PauseModal />

      <div style={{ ...trapezoidCSS, ...trapezoidBase }}>
        <ClassGoalProgress />
        <div style={{ ...timerCSS, ...timerBase }}>
          {!continuoushURL && <h1 style={timerText}>{displayTimeDecrement(timer)}</h1>}
          {continuoushURL && (
            <div>
              <h1>{classId}</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import { CSSProperties } from "react";
import Pause_Button from "../../images/Pause_button.png";
import circleAndPlay from "../../images/circle_and_play.png";
import circleAndX from "../../images/circle_and_x.png";

import { selectCSSClass } from "../helpers/selectCSSClass";

import { pauseSubtext, pauseButtonMobile, pauseButtonLaptop } from "./Boxing.styles";

export const getBottomBarContent = (pathname: string, isTimerRunning: boolean, mode: string, rawWidth: number): JSX.Element | null => {
  const pauseButtonCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: pauseButtonMobile,
    laptop: pauseButtonLaptop
  });

  switch (true) {
    case pathname.endsWith("/boxing"):
      return (
        <>
          {isTimerRunning ? (
            <>
              <img style={pauseButtonCSS} src={Pause_Button} alt="Pause Button" />
              <span style={pauseSubtext}>PAUSE</span>
            </>
          ) : (
            <>
              <img style={pauseButtonCSS} src={circleAndPlay} alt="Play Button" />
              <span style={pauseSubtext}>RESUME</span>
            </>
          )}
        </>
      );
    case pathname.endsWith("/finish"):
      return (
        <>
          <img style={pauseButtonCSS} src={circleAndX} alt="Exit Button" />
          <span style={pauseSubtext}>EXIT</span>
        </>
      );
    case pathname.endsWith("/continuous"):
      return (
        <>
          {mode === "active" ? (
            <img style={pauseButtonCSS} src={Pause_Button} alt="Pause Button" />
          ) : (
            <img style={pauseButtonCSS} src={circleAndPlay} alt="Play Button" />
          )}
          <span style={pauseSubtext}>{mode === "active" ? "PAUSE" : "RESUME"}</span>
        </>
      );
    default:
      return null;
  }
};

// external
import { useNavigate } from "react-router-dom";
import { CSSProperties, useEffect, useState } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import initFirebase from "../../Configs/initFirebase";
import { logEvent } from "firebase/analytics";
import { getWebAnalytics } from "../../Configs/initFirebase";
import { Grid, Container, Button } from "@mui/material";
import useSound from "use-sound";

// hooks
import useResize from "../../Hooks/useResize";
import { useClassContext } from "../../Contexts/ClassContext";
import { useFBOnChildAdded } from "../../Hooks/useFBOnChildAdded";
import { useFBOnChildChanged } from "../../Hooks/useFBOnChildChanged";
import { useFBOnChildRemoved } from "../../Hooks/useFBOnChildRemoved";

// assets
import Powa_logo from "../../images/Powa_logo.png";
import alert_sound from "../../sounds/censor-beep-1sec.mp3";

// components
import Sliders from "./Sliders";
import UserCardSetup from "../MUIComponents/02-UserCardSetup/UserCardSetup";

// helpers
import addComma from "../helpers/addComma";
import addCurrentConfigToFirestore from "./addConfigToFirestore";
import { Mode, User } from "../helpers/CustomTypes";
import { updateMetaWithClassParams } from "./updateMetaWithClassParams";
import { addMockUser } from "../03-BoxingPage/addMockUser";
import { selectCSSClass } from "../helpers/selectCSSClass";

import {
  setupContainerDesktop,
  setupContainerLaptop,
  setupContainerMobile,
  setupContainerTablet,
  classIdLabel,
  userCardSetupGridStyle,
  powaLogo,
  classIdDisplay,
  classIdContainerBase,
  classIdContainerTablet,
  classIdContainerLaptop,
  classIdContainerDesktop,
  punchGoalContainer,
  punchGoalLabelBase,
  centeringGrid,
  devText,
  logoSliderClassInfoLaptop,
  logoSliderClassInfoDesktop,
  punchGoalInputBase,
  punchGoalInputLaptop,
  punchGoalInputTablet,
  punchGoalInputDesktop,
  logoSliderClassInfoTablet,
  logoSliderClassInfoMobile,
  buttonStyleDesktop,
  buttonStyleLaptop,
  buttonStyleMobile,
  buttonStyleBase,
  punchGoalLabelTablet,
  punchGoalLabelDesktop,
  punchGoalLabelLaptop,
  classIdPunchGoalStartBase,
  classIdPunchGoalStartMobile,
  classIdPunchGoalStartLaptop,
  sliderContainerBase,
  sliderContainerLaptop,
  sliderContainerMobile,
  powaLogoContainerMobile,
  powaLogoContainerLaptop,
  powaLogoContainerBase,
  outerContainerMobile,
  outerContainerLaptop,
  outerContainerBase,
  gridContainerBase,
  gridContainerMobile,
  gridContainerLaptop
} from "./Setup.styles";

export default function Setup(): JSX.Element {
  const {
    boxingClass,
    setBoxingClass,
    setTimer,
    startTimer,
    classId,
    numRounds,
    roundLength,
    breakLength,
    classPunchGoal,
    setClassPunchGoal,
    setCurrentRound,
    db,
    currentRound,
    setMode,
    setTotalPunchesAtRoundStart,
    continuousMode,
    setContinuousMode,
    soundsEnabled,
    buildEnv
  } = useClassContext();

  const navigate = useNavigate();

  useFBOnChildAdded(classId, setBoxingClass);
  useFBOnChildChanged(classId, setBoxingClass);
  useFBOnChildRemoved(classId, setBoxingClass);

  const rawWidth: number = useResize()[0];

  const scores = boxingClass[classId]?.scores;
  const userCount = scores ? Object.keys(scores).length : 0;

  const [play] = useSound(alert_sound, {
    interrupt: true,
    soundEnabled: soundsEnabled
  });

  const handleStart = (): void => {
    logEvent(getWebAnalytics(), "workout_start", {
      classId: classId,
      userCount: userCount,
      continuousMode: continuousMode
    });

    const newMode: Mode = "active";
    setMode(newMode);

    if (continuousMode === "disabled") {
      play();
      setTimer(roundLength); // sets time left in round
      startTimer(newMode); // sets class in active state
      setCurrentRound(1);
      navigate("/class/boxing");
    } else if (continuousMode === "enabled") {
      startTimer(newMode); // sets class in active state, no timer
      navigate("/class/continuous");
    } else {
      console.log("Invalid continuous mode: ", continuousMode);
      navigate("/class/boxing");
    }

    // console.log("before adding to firestore. continuousMode: ", continuousMode);
    addCurrentConfigToFirestore({ numRounds, roundLength, breakLength, classPunchGoal, continuousMode });
    // updateMetaWithClassParams({db, classId, breakLength, roundLength, numRounds, currentRound, continuousMode});
    if (continuousMode === "disabled") {
      // we don't need to update meta params because meta already says continuous mode is active since it wrote to RTDB when the switch was flipped
      updateMetaWithClassParams({
        db: db,
        classId: classId,
        breakDuration: breakLength,
        roundDuration: roundLength,
        rounds: numRounds,
        currentRound: currentRound,
        continuousMode: continuousMode
      });
    }
  };

  const firestore = getFirestore(initFirebase());
  const auth = getAuth();
  const userEmail = auth.currentUser?.email;

  useEffect(() => {
    const fetchUserConfig = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.log("No user is currently authenticated.");
        navigate("/login");
        return;
      }

      const docRef = doc(firestore, "gyms", user.email!);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        // console.log("No such document!");
        return;
      }

      const userData = docSnap.data();
      // console.log("User data:", userData);
      if (!userData.workoutPreset) {
        return;
      }

      // Update your state with fetched data
      if (userData.workoutPreset.continuousMode === "enabled" || userData.workoutPreset.continuousMode === "disabled") {
        // only set it if we actually have the value and its valid. Otherwise leave it so it doesn't get set to undefined and cause lots of issues
        setContinuousMode(userData?.workoutPreset?.continuousMode);
      }
    };

    fetchUserConfig();
    setTotalPunchesAtRoundStart({});
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    if (!scores) {
      // console.log("Scores are not available.");
      return;
    }
    if (continuousMode === "enabled") {
      // console.log("Continuous mode is active.");
      return;
    }

    const updatedScores = { ...scores };
    Object.keys(updatedScores).forEach((userId) => {
      updatedScores[userId] = {
        ...updatedScores[userId],
        Punches: { "0": 0 },
        Stars: { "0": 0 },
        RoundPunches: 0,
        Intensity: 0
      };
    });

    setBoxingClass((prevBoxingClass) => ({
      ...prevBoxingClass,
      [classId]: {
        ...prevBoxingClass[classId],
        scores: updatedScores
      }
    }));
  }, []); // Empty dependency array to run only once on mount

  const handleAddMockUser = (): void => {
    addMockUser(db!, classId);
  };

  const handlePurchasePremium = (): void => {
    window.open(window.location.origin, "_blank", "noopener,noreferrer");
  };

  const setupContainerCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: setupContainerMobile,
    tablet: setupContainerTablet,
    laptop: setupContainerLaptop,
    desktop: setupContainerDesktop
  });

  const powaLogoContainerCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: powaLogoContainerMobile,
    laptop: powaLogoContainerLaptop
  });

  const logoSliderClassInfoCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: logoSliderClassInfoMobile,
    tablet: logoSliderClassInfoTablet,
    laptop: logoSliderClassInfoLaptop,
    desktop: logoSliderClassInfoDesktop
  });

  const sliderContainerCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: sliderContainerMobile,
    laptop: sliderContainerLaptop
  });

  const classIdPunchGoalStartCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: classIdPunchGoalStartMobile,
    laptop: classIdPunchGoalStartLaptop
  });

  const classIdContainerCSS: CSSProperties = selectCSSClass(rawWidth, {
    tablet: classIdContainerTablet,
    laptop: classIdContainerLaptop,
    desktop: classIdContainerDesktop
  });

  const punchGoalLabelCSS: CSSProperties = selectCSSClass(rawWidth, {
    tablet: punchGoalLabelTablet,
    laptop: punchGoalLabelLaptop,
    desktop: punchGoalLabelDesktop
  });

  const punchGoalInputCSS: CSSProperties = selectCSSClass(rawWidth, {
    tablet: punchGoalInputTablet,
    laptop: punchGoalInputLaptop,
    desktop: punchGoalInputDesktop
  });

  const buttonStyleCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: buttonStyleMobile,
    laptop: buttonStyleLaptop,
    desktop: buttonStyleDesktop
  });

  //user grid styles
  const outerContainerCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: outerContainerMobile,
    laptop: outerContainerLaptop
  });
  const gridContainerCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: gridContainerMobile,
    laptop: gridContainerLaptop
  });

  return (
    <div style={setupContainerCSS}>
      <div style={logoSliderClassInfoCSS}>
        <div style={{ ...powaLogoContainerBase, ...powaLogoContainerCSS }}>
          <a href="https://powaboxing.com/" target="_blank" rel="noopener noreferrer">
            <img style={powaLogo} src={Powa_logo} alt="Powa Logo" />
          </a>
          {buildEnv === "development" && (
            <>
              <h1 style={devText} onClick={handleAddMockUser}>
                Add Mock User
              </h1>
              <h1 style={devText} onClick={handlePurchasePremium}>
                Purchase Premium
              </h1>
            </>
          )}
        </div>
        <div style={{ ...sliderContainerBase, ...sliderContainerCSS }}>
          <Sliders />
        </div>
        <div style={{ ...classIdPunchGoalStartBase, ...classIdPunchGoalStartCSS }}>
          <div style={{ ...classIdContainerBase, ...classIdContainerCSS }}>
            <h1 style={classIdLabel}>CLASS CODE:</h1>
            <p style={classIdDisplay}>{classId}</p>
          </div>
          <div style={punchGoalContainer}>
            <span style={{ ...punchGoalLabelBase, ...punchGoalLabelCSS }}>CLASS PUNCH GOAL:</span>
            <input
              style={{ ...punchGoalInputBase, ...punchGoalInputCSS }}
              type="text"
              placeholder="ENTER CLASS PUNCH GOAL"
              value={addComma(classPunchGoal)}
              onChange={(e) => {
                // Remove commas before converting to a number. avoids setState errors as it is typed as a number and comma will be death
                const value = e.target.value.replace(/,/g, "");
                const numericValue = Number(value);
                if (!isNaN(numericValue)) {
                  setClassPunchGoal(numericValue);
                }
              }}
            />
          </div>
          <Button variant="contained" onClick={handleStart} style={{ ...buttonStyleBase, ...buttonStyleCSS }}>
            {continuousMode === "enabled" ? "BEGIN SESSION" : "BEGIN WORKOUT"}
          </Button>
        </div>
      </div>

      <Container style={{ ...outerContainerCSS, ...outerContainerBase }}>
        <div style={centeringGrid}>
          <Grid container spacing={2} style={{ ...gridContainerBase, ...gridContainerCSS }}>
            {classId &&
              boxingClass[classId]?.scores &&
              Object.entries(boxingClass[classId].scores).map(([userId, user]: [string, User]) => (
                <Grid style={userCardSetupGridStyle} item key={userId} xs={12} sm={6} md={3} lg={3}>
                  <UserCardSetup user={user} userId={userId} />
                </Grid>
              ))}
          </Grid>
        </div>
      </Container>
    </div>
  );
}

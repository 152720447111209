import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { formatValueText } from "../02-SetupPage/formatValueText";

interface DiscreteSliderProps {
  label: string;
  value: number;
  step: number;
  min: number;
  max: number;
  onChange?: (event: Event, value: number | number[]) => void;
  type: string;
  width: string;
}

export default function DiscreteSlider(props: DiscreteSliderProps) {
  const { label, value, step, min, max, onChange, type, width } = props;

  return (
    // <Box sx={{ width: "100%" }}>
    <Box sx={{ width: width }}>
      <Slider
        aria-label={label}
        value={value}
        getAriaValueText={(sliderValue) => formatValueText(sliderValue, type)}
        valueLabelDisplay="auto"
        step={step}
        min={min}
        max={max}
        onChange={onChange}
        sx={{
          height: 15, // Make the Slider track 15px thick
          "& .MuiSlider-thumb": {
            height: 24, // Increase the height of the thumb for a larger appearance
            width: 24, // Increase the width of the thumb for a larger appearance
            color: "#F2662D" // Slider thumb color
            // border: "1px solid black" // Add border to the thumb if needed
          },
          "& .MuiSlider-track": {
            background: "linear-gradient(to right, #F9A82C, #F2662D)", // Gradient for  fill L to R
            height: "inherit", // Ensure the track height matches the Slider height
            border: "0.5px solid black" // Add a solid black border around the track
          },
          "& .MuiSlider-rail": {
            backgroundColor: "#404040", // Color for the non-filled portion of the Slider
            height: "inherit", // Ensure the rail height matches the Slider height
            opacity: 1, // Adjust the opacity as needed
            border: "1px solid black" // Add a solid black border around the rail
          }
        }}
      />
    </Box>
  );
}

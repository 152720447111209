import { CSSProperties } from "react";

export const loginPageStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column", // This is correctly typed now
  gap: "20px",
  height: "100vh",
  paddingTop: "10vh",
  color: "#FFFFFF"
};

export const powaLogoContainer: CSSProperties = {
  maxHeight: "50vh"
};

export const powaLogo: CSSProperties = {
  maxHeight: "50vh",
  maxWidth: "50vw",
  paddingBottom: "20px"
};

export const buttonStyleMobile: CSSProperties = {
  width: "80vw",
  maxWidth: "400px",
  height: "70px",
  borderRadius: "8px",
  fontSize: "24px",
  letterSpacing: "2px"
};

export const buttonStyleLaptop: CSSProperties = {
  width: "400px",
  height: "70px",
  borderRadius: "8px",
  fontSize: "24px",
  letterSpacing: "2px"
};

export const buttonStyleBase: CSSProperties = {
  background: "#f2662D",
  width: "400px",
  height: "70px",
  borderRadius: "8px",
  fontSize: "24px",
  letterSpacing: "2px",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 700,
  color: "#FFFFFF"
};

import { CSSProperties } from "react";

type CSSClassObject = {
  mobile?: CSSProperties;
  tablet?: CSSProperties;
  laptop: CSSProperties;
  desktop?: CSSProperties;
};

/**
 * Selects a CSS class based on the width of the screen
 * @param {number} width - the width of the screen
 * @param {CSSClassObject} classes - an object containing the different CSS classes
 * @returns {CSSProperties} the CSS class appropriate for the screen width
 */

export function selectCSSClass(width: number, classes: CSSClassObject): CSSProperties {
  if (width < 1200) {
    if (width < 900 && classes.mobile) {
      return classes.mobile;
    } else if (classes.tablet) {
      return classes.tablet;
    } else {
      return classes.laptop;
    }
  } else {
    if (width < 1550 && classes.laptop) {
      return classes.laptop; // TODO if I only provide laptop (most important one as defined in line 6) then it will error out if screen is larger than 1550 as no desktop was handed in
    } else if (classes.desktop) {
      return classes.desktop;
    } else if (classes.laptop) {
      return classes.laptop;
    }
  }
  // something has gone wrong
  // console.error("No CSS provided");
  throw new Error(`No CSS provided in component: ${JSON.stringify(classes)}`);
  // needs filler
  // return { border: "2px solid red" };
}

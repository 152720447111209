import { Grid } from "@mui/material"; // Assuming MUI components

import { useClassContext } from "../../Contexts/ClassContext";
import { useFBOnChildAdded } from "../../Hooks/useFBOnChildAdded";
import { useFBOnChildChanged } from "../../Hooks/useFBOnChildChanged";
import { useFBOnChildRemoved } from "../../Hooks/useFBOnChildRemoved";
import { User } from "../helpers/CustomTypes"; // Import the User type
import calcGridStylesAndHeightFinish from "../04-FinishPage/CalcGridStylesAndHeightFinish";
import sumSessions from "./sumSessions";
import useResize from "../../Hooks/useResize";

import { userCardBoxingGridStyles } from "../03-BoxingPage/Boxing.styles";
import UserCardFinish from "../MUIComponents/04-UserCardFinish/UserCardFinish";

const ContinuousUserGrid = () => {
  const { classId, boxingClass, setBoxingClass, totalPunchesAtRoundStart } = useClassContext();
  const scores = boxingClass[classId]?.scores;
  const userCount = scores ? Object.keys(scores).length : 0;
  const rawWidth: number = useResize()[0];

  useFBOnChildAdded(classId, setBoxingClass);
  useFBOnChildChanged(classId, setBoxingClass);
  useFBOnChildRemoved(classId, setBoxingClass);

  if (!scores) {
    return <></>;
  }

  const userArray = Object.entries(scores).map(([userId, user]: [string, User]) => {
    const punches = sumSessions(user, "Punches");

    return { userId, user, punches };
  });
  // console.log("userArray: ", userArray);
  const sortedArray = userArray.sort((a, b) => {
    // First sort by Status ("active" first)
    if (a.user.Status === "active" && b.user.Status !== "active") {
      return -1; // a should come before b
    } else if (a.user.Status !== "active" && b.user.Status === "active") {
      return 1; // b should come before a
      // If both have the same Status or both are not "active", sort by punchststart in descending order
    } else {
      if (a.user.Status === "active" && b.user.Status === "active") {
        return parseInt(a.userId) - parseInt(b.userId); // userId is string we need to convert to make them sortable
      }
      // if both users aren't active, we sort by punches. this is primarily for inactive users to put highest punches on top
      return b.punches - a.punches;
    }
  });
  // console.log("sortedArray: ", sortedArray);
  const userElements = sortedArray.map(({ userId, user }) => {
    const { gridSizes, height, internalStyle } = calcGridStylesAndHeightFinish(userCount, rawWidth);

    return (
      <Grid item key={userId} style={{ ...userCardBoxingGridStyles, height: height }} {...gridSizes}>
        <UserCardFinish styles={internalStyle} user={{ ...user }} />
      </Grid>
    );
  });
  // console.log("userElements: ", userElements);
  return userElements;
};

export default ContinuousUserGrid;

import { CSSProperties } from "react";

export const slidersContainerStyle: CSSProperties = {
  display: "flex",
  width: "100%",
  height: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between", // why does this property not apply?
  color: "#FFFFFF",
  position: "relative"
};

export const rowContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
};

export const numberDisplayTablet: CSSProperties = {
  fontSize: "24px"
};
export const numberDisplayLaptop: CSSProperties = {
  fontSize: "24px"
};
export const numberDisplayDesktop: CSSProperties = {
  fontSize: "36px"
};

export const numberDisplayBase: CSSProperties = {
  color: "#FD4B03",
  fontWeight: "bold",
  paddingBottom: "4px",
  paddingRight: "6px"
};

export const unitsDisplayMobile: CSSProperties = {
  letterSpacing: "3px",
  fontSize: "12px"
};
export const unitsDisplayLaptop: CSSProperties = {
  letterSpacing: "6px",
  fontSize: "12px"
};

export const volumeDiv: CSSProperties = {
  position: "absolute", // Positioning volume div absolutely
  left: "5px", // Align it to the left
  top: "15px", // Center it vertically
  transform: "translateY(-50%)", // Adjust vertical alignment
  width: "57px", // Set width to enclose the icon
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "5px", // optional coloured border
  border: "3px solid #FD4B03",
  borderRadius: "5px",
  cursor: "pointer"
};

export const volumeIcon: CSSProperties = {
  height: "40px"
};

export const sliderMobile: CSSProperties = {
  width: "80vw"
};

export const sliderLaptop: CSSProperties = {
  width: "49vw"
};

export const sliderLabelMobile: CSSProperties = {
  letterSpacing: "6px",
  fontSize: "18px"
};
export const sliderLabelLaptop: CSSProperties = {
  letterSpacing: "15px",
  fontSize: "18px"
};
export const sliderLabelBase: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: "bolder"
};

export const smoothTransition: CSSProperties = {
  transition: "all 0.3s ease-in-out"
};

export const greyFilterStyle: CSSProperties = {
  position: "relative",
  filter: "grayscale(100%)",
  opacity: 0.5
};

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { CSSProperties } from "react";

import { useClassContext } from "../../../Contexts/ClassContext";
import { selectCSSClass } from "../../helpers/selectCSSClass";
import useResize from "../../../Hooks/useResize";
import clearClassScores from "../../00-Login/clearClassScores";
import logWorkoutCompleted from "../../04-FinishPage/logWorkoutCompleted";

import Modal_x_button from "../../../images/Modal_x_button.png";

import {
  modalContent,
  confirmButtonStyle,
  cancelButtonStyle,
  modalMobile,
  modalLaptop,
  modalTitleMobile,
  modalTitleLaptop,
  modalBase,
  modalTitleBase,
  confirmationMessageBase,
  confirmationMessageMobile,
  confirmationMessageLaptop
} from "./ConfirmModal.styles";

import {
  buttonBase,
  buttonLaptop,
  buttonMobile,
  roundedCorners,
  xButton,
  xButtonContainerBase,
  xButtonContainerLaptop,
  xButtonContainerMobile
} from "./PauseModal.styles";

type ConfirmModalProps = {
  isOpen: boolean;
  onDismiss: (isOpen: boolean) => void;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const { classId, setOpenModal, stopTimer, setTimer, setIsBreakTime, setCurrentRound, setTotalPunchesAtRoundStart, db } =
    useClassContext();
  const { isOpen, onDismiss } = props;
  const navigate = useNavigate();
  const rawWidth: number = useResize()[0];

  // console.log("in ConfirmModal. openConfirmModal: ", openConfirmModal);

  const handleClose = () => {
    onDismiss(false);
  };

  const handleConfirm = () => {
    // console.log("cont mode: ", continuousMode);
    onDismiss(false);
    setOpenModal(false);
    // setMode("break"); // stopTimer sets mode to completed
    stopTimer("completed");
    setTimer(0);
    setIsBreakTime(false);
    setCurrentRound(1);
    setTotalPunchesAtRoundStart({}); // reset punches in local state so we can start fresh on the next round/class
    clearClassScores(db!, classId);
    navigate("/class");
    logWorkoutCompleted(classId);
  };

  const modalCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: modalMobile,
    laptop: modalLaptop
  });

  const modalTitleCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: modalTitleMobile,
    laptop: modalTitleLaptop
  });

  const xButtonContainerCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: xButtonContainerMobile,
    laptop: xButtonContainerLaptop
  });

  const confirmationMessageCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: confirmationMessageMobile,
    laptop: confirmationMessageLaptop
  });
  const buttonCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: buttonMobile,
    laptop: buttonLaptop
  });

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 2 }}
      >
        <Box sx={{ ...modalCSS, ...modalBase }}>
          <div style={roundedCorners}>
            <div style={{ ...modalTitleCSS, ...modalTitleBase }}>
              <span>{"CONFIRM"}</span>

              <div style={{ ...xButtonContainerCSS, ...xButtonContainerBase }}>
                <img onClick={handleClose} style={xButton} src={Modal_x_button} alt="" />
              </div>
            </div>
            <div style={modalContent}>
              <h3 style={{ ...confirmationMessageBase, ...confirmationMessageCSS }}>
                Are you sure you want to delete all user data for this class?
              </h3>
              <Button onClick={handleConfirm} style={{ ...buttonBase, ...buttonCSS, ...confirmButtonStyle }}>
                CONFIRM
              </Button>
              <Button onClick={handleClose} style={{ ...buttonBase, ...buttonCSS, ...cancelButtonStyle }}>
                CANCEL
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmModal;

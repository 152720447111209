import { Grid } from "@mui/material";

import { useClassContext } from "../../Contexts/ClassContext";
import { useFBOnChildAdded } from "../../Hooks/useFBOnChildAdded";
import { useFBOnChildChanged } from "../../Hooks/useFBOnChildChanged";
import { useFBOnChildRemoved } from "../../Hooks/useFBOnChildRemoved";
import { User } from "../helpers/CustomTypes";
import calculateGridStylesAndHeight from "./CalculateGridStylesAndHeight";
import useResize from "../../Hooks/useResize";

import { userCardBoxingGridStyles } from "./Boxing.styles";
import UserCardBoxing from "../MUIComponents/03-UserCardBoxing/UserCardBoxing";
import UserCardBreak from "../MUIComponents/03.1-UserCardBreak/UserCardBreak";

const BoxingUserGrid = () => {
  const { classId, boxingClass, setBoxingClass, totalPunchesAtRoundStart, isBreakTime } = useClassContext();
  const scores = boxingClass[classId]?.scores;
  const userCount = scores ? Object.keys(scores).length : 0;
  const rawWidth: number = useResize()[0];

  useFBOnChildAdded(classId, setBoxingClass);
  useFBOnChildChanged(classId, setBoxingClass);
  useFBOnChildRemoved(classId, setBoxingClass);

  return (
    <>
      {classId &&
        scores &&
        Object.entries(scores).map(([userId, user]: [string, User]) => {
          const { gridSizes, height, internalStyle } = calculateGridStylesAndHeight(userCount, rawWidth);
          const punchesAtStart: { [session: string]: number } = totalPunchesAtRoundStart[classId]?.scores[userId]?.Punches || { "0": 0 };

          user.Punches = user.Punches || { "0": 0 };
          user.Punches[0] = user.Punches[0] ?? 0; // set to zero if null or undefined using nullish coalescing operator

          // we only take the first session, this isn't continuous mode so there should only be one session
          const roundPunches: number = user.Punches[0] - punchesAtStart[0];

          return (
            <Grid item key={userId} style={{ ...userCardBoxingGridStyles, height: height }} {...gridSizes}>
              {isBreakTime ? (
                <UserCardBreak styles={internalStyle} user={{ ...user, RoundPunches: roundPunches }} />
              ) : (
                <UserCardBoxing styles={internalStyle} user={{ ...user, RoundPunches: roundPunches }} />
              )}
            </Grid>
          );
        })}
    </>
  );
};

export default BoxingUserGrid;

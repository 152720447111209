import { useEffect, useState } from "react";

/**
 * https://quantumumi.medium.com/custom-useresize-hook-ea11bac11b4e
 * @returns [width, height]
 */

const useResize = () => {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    // console.log("count useResize Calls. In useEffect callback");
    const getSize = () => setSize([window.innerWidth, window.innerHeight]);
    getSize();
    window.addEventListener("resize", getSize);
    return () => window.removeEventListener("resize", getSize);
  }, []);
  return size;
};
export default useResize;

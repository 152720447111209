import { CSSProperties } from "react";

export const containerStyles: CSSProperties = {
  // paddingTop: "10px",
  // marginTop: "20px",
  maxWidth: "100%",
  padding: "0px 0px 120px 0px" // top, right , bottom, left
};

export const gridContainerStyles: CSSProperties = {
  display: "flex",
  alignItems: "center", // Change from center to stretch to allow children to fill the container height
  justifyContent: "center",
  margin: "0px",
  width: "100%"
};

export const bottomBar: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  position: "fixed",
  bottom: "0",
  left: "0",
  width: "100vw",
  backgroundImage: "linear-gradient(180deg, #151516, #101010)" // #starts at 151516 in reg screen and 141415 in fullscreen on 1080p
};

export const pauseButtonWithSubtext: CSSProperties = {
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "5px",
  gap: "2px",
  position: "relative",
  minWidth: "80px"
};

export const pauseButtonMobile: CSSProperties = {
  width: "50px",
  paddingTop: "20px"
};

export const pauseButtonLaptop: CSSProperties = {
  width: "87px"
};

export const pauseSubtext: CSSProperties = {
  color: "#EF582B",
  fontSize: "19px",
  fontWeight: "Bold"
};

export const playButtonCircle: CSSProperties = {
  display: "block",
  width: "100%"
};

export const playIcon: CSSProperties = {
  position: "absolute",
  transform: "translate(35px, -61px)" // stick the play icon in the middle of the circle full jank
};

export const OrangeX: CSSProperties = {
  position: "absolute",
  transform: "translate(29px, -58px)" // stick the play icon in the middle of the circle full jank
};

export const closeIcon: CSSProperties = {
  position: "absolute",
  transform: "translate(29px, -58px)" // stick the play icon in the middle of the circle full jank
};

export const trapezoidMobile: CSSProperties = {
  clipPath: `polygon(0 100%, 0% 0, 100% 0, 100% 100%)`,
  width: "100%" // outer width
};
export const trapezoidLaptop: CSSProperties = {
  clipPath: `polygon(0 100%, 2.4% 0, 100% 0, 100% 100%)`,
  width: "92.81vw" // outer width
};

export const trapezoidBase: CSSProperties = {
  backgroundColor: "#3B3B3B",
  height: "120px", // 120px or 11.11vh
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
};

export const currentStatusStyles: CSSProperties = {
  fontSize: "25px",
  margin: "0"
};

export const userCardBoxingGridStyles: CSSProperties = {
  display: "flex", // Ensure this acts as a flex container for the card
  flexGrow: "1",
  padding: "2px",
  flexDirection: "column"
};

export const punchGoalSliderContainer: CSSProperties = {
  // marginTop: "30px"
};

export const timerMobile: CSSProperties = {
  // width: "30%",
  // minWidth: "50px",
  fontSize: "18px",
  letterSpacing: "5px",
  padding: "0px 15px 0px 20px" // top, right , bottom, left
};

export const timerLaptop: CSSProperties = {
  width: "200px",
  minWidth: "200px",
  fontSize: "3rem",
  letterSpacing: "10px",
  padding: "0px 20px 0px 50px" // top, right , bottom, left
};

export const timerBase: CSSProperties = {
  // marginTop: "30px"
  // display: "flex",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#292929",
  color: "#FFFFFF"
};

export const timerText: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "start"
  // maxWidth: "120px",
  // minWidth: "120px"
};

import DiscreteSlider from "../MUIComponents/DiscreteSlider";
import ContinuousSwitch from "../MUIComponents/Switch";
import useSound from "use-sound";
import { CSSProperties } from "react";

import { displayTimeStandard } from "./DisplayTimeStandard";
import { useClassContext } from "../../Contexts/ClassContext";
import useResize from "../../Hooks/useResize";
import { selectCSSClass } from "../helpers/selectCSSClass";

import volume_on from "../../images/volume_on.png";
import volume_off from "../../images/volume_off.png";
import alert_sound from "../../sounds/censor-beep-1sec.mp3";

import {
  slidersContainerStyle,
  numberDisplayBase,
  numberDisplayTablet,
  numberDisplayLaptop,
  numberDisplayDesktop,
  rowContainerStyle,
  smoothTransition,
  volumeIcon,
  volumeDiv,
  unitsDisplayLaptop,
  unitsDisplayMobile,
  sliderLabelMobile,
  sliderLabelLaptop,
  sliderLabelBase,
  sliderMobile,
  sliderLaptop
} from "./Sliders.styles";

export default function Sliders() {
  const {
    numRounds,
    setNumRounds,
    roundLength,
    setRoundLength,
    breakLength,
    setBreakLength,
    continuousMode,
    soundsEnabled,
    setSoundsEnabled
  } = useClassContext();

  const rawWidth: number = useResize()[0];

  const [play] = useSound(alert_sound, {
    interrupt: true
    // rm this allows us to circumvent the async setState on line 61
    // soundEnabled: soundsEnabled
  });

  const handleRoundsChange = (event: Event, value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    setNumRounds(newValue);
  };

  const handleRoundLengthChange = (event: Event, value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    setRoundLength(newValue);
  };

  const handleBreakLengthChange = (event: Event, value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    setBreakLength(newValue);
  };

  const handleSoundsEnable = (): void => {
    if (soundsEnabled) {
      setSoundsEnabled(false);
    } else {
      setSoundsEnabled(true);
      play();
    }
  };

  const sliderLabelCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: sliderLabelMobile,
    laptop: sliderLabelLaptop
  });

  const sliderWidth: CSSProperties = selectCSSClass(rawWidth, {
    mobile: sliderMobile,
    laptop: sliderLaptop
  });
  // the mui component accepts string, so we dot in and assert it as string to get it directly and avoid type issues of string | undefined | CSSProperties
  const sliderWidthString = sliderWidth.width as string;

  const numberDisplayCSS: CSSProperties = selectCSSClass(rawWidth, {
    tablet: numberDisplayTablet,
    laptop: numberDisplayLaptop,
    desktop: numberDisplayDesktop
  });

  const unitsDisplayCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: unitsDisplayMobile,
    laptop: unitsDisplayLaptop
  });

  return (
    <div style={slidersContainerStyle}>
      {continuousMode === "disabled" && (
        <div style={volumeDiv} onClick={handleSoundsEnable}>
          <img style={volumeIcon} src={soundsEnabled ? volume_on : volume_off} alt="Sound Enable/Disable" />
        </div>
      )}
      <div style={rowContainerStyle}>
        <h3 style={{ ...sliderLabelBase, ...sliderLabelCSS }}>
          <ContinuousSwitch /> {continuousMode === "enabled" ? "OPEN" : "ROUNDS"}
        </h3>
      </div>
      {/* <div style={continuousMode === "enabled" ? greyFilterStyle : {}}> */}
      {continuousMode === "disabled" && (
        <div key="disabled" style={smoothTransition}>
          <div style={rowContainerStyle}>
            <span style={{ ...numberDisplayBase, ...numberDisplayCSS }}>{numRounds}</span>
            <span style={unitsDisplayCSS}>{numRounds === 1 ? "ROUND" : "ROUNDS"}</span>
            <DiscreteSlider
              label="Number of Rounds"
              value={numRounds}
              type={"rounds"}
              step={1}
              min={1}
              max={15}
              onChange={handleRoundsChange}
              width={sliderWidthString}
            />
            <h3 style={{ ...sliderLabelBase, ...sliderLabelCSS }}>NUMBER OF ROUNDS</h3>
          </div>
          <div style={rowContainerStyle}>
            <span style={{ ...numberDisplayBase, ...numberDisplayCSS }}>{displayTimeStandard(roundLength)}</span>
            <span style={unitsDisplayCSS}>{roundLength === 60 ? "MINUTE" : roundLength > 60 ? "MINUTES" : "SECONDS"}</span>
            <DiscreteSlider
              label="Round Length"
              value={roundLength}
              type={"time"}
              step={5}
              min={5}
              max={180}
              onChange={handleRoundLengthChange}
              width={sliderWidthString}
            />
            <h3 style={{ ...sliderLabelBase, ...sliderLabelCSS }}>ROUND DURATION</h3>
          </div>
          <div style={rowContainerStyle}>
            <span style={{ ...numberDisplayBase, ...numberDisplayCSS }}>{displayTimeStandard(breakLength)}</span>
            <span style={unitsDisplayCSS}>{breakLength === 60 ? "MINUTE" : breakLength > 60 ? "MINUTES" : "SECONDS"}</span>
            <DiscreteSlider
              label="Break Length"
              value={breakLength}
              type={"time"}
              step={5}
              min={5}
              max={180}
              onChange={handleBreakLengthChange}
              width={sliderWidthString}
            />
            <h3 style={{ ...sliderLabelBase, ...sliderLabelCSS }}>BREAK DURATION</h3>
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
}

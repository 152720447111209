// UserCard.js // https://www.youtube.com/watch?v=M75MUZ1zVYM guide for basic card
// https://www.youtube.com/watch?v=5cvg2qZwYrI mui usage
import Card from "@mui/material/Card";
import { CSSProperties } from "react";
import { CardHeader } from "@mui/material";

import { User } from "../../helpers/CustomTypes"; // Import User type
import { useClassContext } from "../../../Contexts/ClassContext";
import Modal_x_button from "../../../images/Modal_x_button.png";
import { deleteUser } from "./deleteUser";
import useResize from "../../../Hooks/useResize";
import { selectCSSClass } from "../../helpers/selectCSSClass";

import {
  NameStyles,
  userCardSetupBase,
  userCardSetupLaptop,
  userCardSetupMobile,
  xButtonContainer,
  xButton,
  emojiSize,
  inlineContainer
} from "./UserCardSetup.styles";

interface UserCardProps {
  user: User;
  userId: string;
}

export default function UserCardSetup({ user, userId }: UserCardProps) {
  const { classId, db } = useClassContext();
  const rawWidth: number = useResize()[0];

  if (!user || !user?.Name) {
    // sometimes the mobile app writes stuff after a user has been deleted, leading to a blank user
    return <></>;
  }

  const handleDeleteUser = async () => {
    try {
      await deleteUser(db!, classId, userId);
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  const nameUpperCase = typeof user?.Name === "string" ? user.Name.toUpperCase() : String(user?.Name || "");

  const userCardSetupCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: userCardSetupMobile,
    laptop: userCardSetupLaptop
  });

  return (
    <Card elevation={6} style={{ ...userCardSetupCSS, ...userCardSetupBase }}>
      <CardHeader
        titleTypographyProps={{ sx: NameStyles }}
        title={
          <span style={inlineContainer}>
            {nameUpperCase} <span style={emojiSize}>{user?.Emoji}</span>
          </span>
        }
      />

      <div style={xButtonContainer}>
        <img onClick={handleDeleteUser} src={Modal_x_button} alt="Delete User" style={xButton} />
      </div>
    </Card>
  );
}

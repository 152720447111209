// external
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { CSSProperties, useEffect } from "react";

// assets
import Powa_logo from "../../images/Powa_logo_cropped.png";

// helpers
import { useClassContext } from "../../Contexts/ClassContext";
import useResize from "../../Hooks/useResize";
import googleSignIn from "./googleSignIn";
import initFirebase from "../../Configs/initFirebase";
import { selectCSSClass } from "../helpers/selectCSSClass";

import { loginPageStyles, powaLogoContainer, powaLogo, buttonStyleLaptop, buttonStyleMobile, buttonStyleBase } from "./Login.styles";

export default function Login() {
  const {
    setClassId,
    setFirebaseClassIDs,
    setDb,
    setContinuousMode,
    setOpenModal,
    setIsTimerRunning,
    setPrevMode,
    setMode,
    buildEnv,
    setBuildEnv
  } = useClassContext();
  const navigate = useNavigate();
  const rawWidth: number = useResize()[0];

  // console.log(useResize()); // [width, height] in px

  useEffect(() => {
    // console.log("calling useEffect setBuildEnv should be there: ", buildEnv, "typeof setBuildEnv: ", typeof setBuildEnv);
    initFirebase(setBuildEnv);
  }, []);

  const handleGoogleSignIn = () => {
    googleSignIn(
      setClassId,
      setFirebaseClassIDs,
      setDb,
      setContinuousMode,
      setOpenModal,
      navigate,
      setIsTimerRunning,
      setPrevMode,
      setMode,
      buildEnv
    );
  };

  const buttonStyleCSS: CSSProperties = selectCSSClass(rawWidth, {
    mobile: buttonStyleMobile,
    laptop: buttonStyleLaptop
  });

  return (
    <div style={loginPageStyles}>
      <div style={powaLogoContainer}>
        <img style={powaLogo} src={Powa_logo} alt="Powa Logo" />
      </div>
      <Button onClick={handleGoogleSignIn} style={{ ...buttonStyleBase, ...buttonStyleCSS }} variant="contained">
        Sign in
      </Button>
      <a
        href="https://powaboxing.com/pages/contact"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Button variant="contained" style={{ ...buttonStyleBase, ...buttonStyleCSS }}>
          CONTACT US
        </Button>
      </a>
    </div>
  );
}
